import React, { useEffect, useState } from 'react';
//import './AlertBox.css'; // Import CSS for styling
import ImageUpload from './ImageUpload';
import axios from 'axios';

function Organisation({ type, message, onClose }) {


    const [industries, setIndustries] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [organisationName, setOrganisationName] = useState('');
    const [organisationLocation, setOrganisationLocation] = useState('');
    const [street1, setStreet1] = useState('');
    const [street2, setStreet2] = useState('');
    const [city, setCity] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [stateName, setStateName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [faxNumber, setFaxNumber] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');

    const [previewUrl, setPreviewUrl] = useState(null);
    const [logo, setLogo] = useState(null);
    const [organizationEmail, setOrganizationEmail] = useState('');

    const [country, setCountry] = useState('');
    const [addressStreet1, setAddressStreet1] = useState('');
    const [baseCurrency, setBaseCurrency] = useState('');
    const [financialYear, setFinancialYear] = useState('');
    const [language, setLanguage] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [dateFormat, setDateFormat] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [taxId, setTaxId] = useState('');
    const [emailID, setEmailID] = useState('');

    useEffect(() => {
        setOrganizationEmail(sessionStorage.getItem('UserName'));
        //setOrganizationEmail("amdesai@gmail.com");
        LoadOrganizationData();

        fetchIndustries();
    }, []);

    const LoadOrganizationData = () => {
        axios.post('https://billing.somee.com/api/Organization/GetOrganizationDetails', organizationEmail,
            {
                headers: {
                    'Content-Type': 'application/json', // Specify content type
                },
            }
        )
            .then(response => {
                debugger
                if (response.data.success === true) {
                    const organizationData = JSON.parse(response.data.data);

                    if (Array.isArray(organizationData) && organizationData.length > 0) {
                        const org = organizationData[0];
                        setOrganisationName(org.OrganizationName);
                        setSelectedIndustry(org.BusinessType);
                        setSelectedIndustry(org.IndustryType);
                        setCountry(org.Country);
                        setStateName(org.State);
                        setCity(org.City);
                        setContactNumber(org.Mobile);
                        setPinCode(org.PinCode);
                        setOrganisationLocation(org.AddressStreet1);
                        setWebsiteUrl(org.WebUrl);
                        setLogo(org.Logo);

                        setBaseCurrency(org.BaseCurrency);
                        setFinancialYear(org.FinancialYear);
                        setLanguage(org.Language);
                        setTimeZone(org.TimeZone);
                        setDateFormat(org.DateFormat);
                        setCompanyId(org.CompanyId);
                        setTaxId(org.TaxId);
                        setEmailID(org.EmailID);
                    } else {
                        console.error("Expected an array but received:", organizationData);
                    }

                } else {
                    
                }
            })
            .catch(error => {
                console.error('Email verification failed:', error.message);
            });
    };

    const fetchIndustries = async () => {
        axios.post('https://billing.somee.com/api/Organization/GetIndustryType')
            .then(response => {
                debugger
                if (response.data.success === true) {
                    const industryArray = JSON.parse(response.data.data);

                    if (Array.isArray(industryArray)) { 
                        setIndustries(industryArray);
                    } else {
                        console.error('API did not return an array');
                    }
                } else {
                    
                }
            })
            .catch(error => {
                console.error('Email verification failed:', error.message);
            });
    };

    const handleIndustryChange = (e) => {
        setSelectedIndustry(e.target.value);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setLogo(file);
                setPreviewUrl(reader.result); // Set preview URL for displaying the image
                console.log(reader.result);
            };

            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };

    const SaveButton = () =>
    {
        const formData = {
            "organizationName": organisationName,
            "industry": selectedIndustry,
            "businessType": selectedIndustry,
            "country": country,
            "mobile": contactNumber,
            "city": city,
            "state": stateName,
            "pinCode": pinCode,
            "addressStreet1": organisationLocation,
            "webUrl": websiteUrl,
            "logo": previewUrl,
            "baseCurrency": "Indian Rupee",
            "financialYear": "2023-2024",
            "language": "English",
            "timeZone": "string",
            "dateFormat": "dd/MM/yyyy",
            "companyId": "1",
            "taxId": "1",
            "emailId": sessionStorage.getItem('UserName')
        };

        debugger

        try {
            const response = axios.post('https://billing.somee.com/api/Organization/UpdateOrganizationData', formData);
            debugger
            console.log('Save successful!', response.data);
        } catch (error) {
            console.error('Error saving data', error);
        }

    }

    const inputStyle = {
        border: '1px solid #ccc',
        borderRadius: '15px',  // Rounded corners
        padding: '10px',
        fontSize: '14px',
        width: '100%',  // Full width within the column
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',  // Optional shadow for depth
        outline: 'none',
        transition: 'border-color 0.3s ease',

        // Change border color on focus
        '&:focus': {
            borderColor: '#007bff',  // Change this color to your desired focus color
        }
    };


    return (
        <div className="container" style={{ paddingLeft: '10px', paddingTop: '20px', paddingBottom: '20px', backgroundColor: '#ffffff', borderRadius: '5px' }}>
            <div className="row" style={{ marginLeft : '3px', position: 'sticky', top: '0', backgroundColor: '#ffffff', zIndex: 10 }}>
                <h2>Organisation Profile  </h2>
                <div className="rounded-id-box" style={{ borderRadius: '15px', backgroundColor: '#f0f0f0', padding: '10px' }}>
                    ID : 60032673561
                </div>
            </div>  

            <div className="row mb-4" style={{ marginLeft: '-10px' }}>

                <div className="container mt-4">
                    <h5>Organization Logo</h5>
                    <div className="upload-description-container">
                        <div className="upload-box">
                            <input
                                type="file"
                                id="file-input"
                                style={{ display: 'none' }} // Hide the input field
                                accept="image/*"
                                onChange={handleImageUpload}
                            />

                            {previewUrl ? (
                                <img
                                    src={previewUrl}
                                    alt="Uploaded Logo"
                                    style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                                />
                            ) : (
                                <label
                                    htmlFor="file-input" // Link to the hidden input field
                                    className="upload-label"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <span className="upload-icon"></span> {/* Optional: Add an upload icon */}
                                    Upload Your Organization Logo
                                </label>
                            )}
                        </div>

                        <div className="description">
                            <p>This logo will be displayed in transaction PDFs and email notifications.</p>
                            <small>Preferred Image Dimensions: 240 � 240 pixels @ 72 DPI</small><br />
                            <small>Maximum File Size: 1MB</small>
                        </div>
                    </div>
                </div>


                {/*<div className="col-12">    
                    <img
                        src="https://img.freepik.com/free-photo/wide-angle-shot-single-tree-growing-clouded-sky-sunset-surrounded-by-grass_181624-22807.jpg"
                        alt="Nature"
                        style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                    />
                </div>*/}
                {/*<ImageUpload />*/}
            </div>

            <div className="row input-row">
                <div className="col-3">
                    <p>
                        Organisation Name
                        <span style={{ color: 'red' }}>*</span>
                    </p>
                </div>
                <div className="col-6">
                    <input
                        type="text"
                        className="form-control rounded-input"
                        placeholder="Enter Organisation Name"
                        value={organisationName}
                        onChange={(e) => setOrganisationName(e.target.value)}
                    />
                </div>
                <div className="col-3"></div>
            </div>

            <div className="row input-row">
                <div className="col-3">
                    <p>
                        Industry
                        <span style={{ color: 'red' }}>*</span>
                    </p>
                </div>
                <div className="col-6">
                    <select
                        className="form-control rounded-input"
                        value={selectedIndustry}
                        onChange={handleIndustryChange}
                    >
                        <option value="">Select Industry</option>
                        {industries.map((industry) => (
                            <option key={industry.IndustryTypeMasterId} value={industry.IndustryType}>
                                {industry.IndustryType}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-3"></div>
            </div>

            {/*<div className="row input-row">
                <div className="col-3">
                    <p>
                        Industry
                        <span style={{ color: 'red' }}>*</span>
                    </p>
                </div>
                <div className="col-6">
                    <input
                        type="text"
                        className="form-control rounded-input"
                        placeholder="Enter Industry"
                    />
                </div>
                <div className="col-3"></div>
            </div>*/}

            <div className="row input-row">
                <div className="col-3">
                    <p>
                        Organisation Location
                        <span style={{ color: 'red' }}>*</span>
                    </p>
                </div>
                <div className="col-6">
                    <input
                        type="text"
                        className="form-control rounded-input"
                        placeholder="Enter Organisation Location"
                        value={organisationLocation}
                        onChange={(e) => setOrganisationLocation(e.target.value)}
                    />
                </div>
                <div className="col-3"></div>
            </div>

            <div className="row input-row">
                <div className="col-3">
                    <p>
                        Organisation Address
                        <span style={{ color: 'red' }}>*</span>
                    </p>
                </div>
                <div className="col-6">
                    <input
                        type="text"
                        className="form-control rounded-input"
                        placeholder="Street 1"
                        style={{ marginBottom: '10px' }}
                        value={street1}
                        onChange={(e) => setStreet1(e.target.value)}
                    />
                    <input
                        type="text"
                        className="form-control rounded-input"
                        placeholder="Street 2"
                        style={{ marginBottom: '10px' }}
                        value={street2}
                        onChange={(e) => setStreet2(e.target.value)}
                    />
                    <div className="row">
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control rounded-input"
                                placeholder="City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="text"
                                className={`form-control rounded-input input-spacing`} // Custom margin class
                                placeholder="Pin Code"
                                value={pinCode}
                                onChange={(e) => setPinCode(e.target.value)}
                                style={{ marginBottom: '10px' }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control rounded-input"
                                placeholder="Maharashtra"
                                value={stateName}
                                onChange={(e) => setStateName(e.target.value)}
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="text"
                                className={`form-control rounded-input input-spacing`} // Custom margin class
                                placeholder="7028534834"
                                value={contactNumber}
                                onChange={(e) => setContactNumber(e.target.value)}
                                style={{ marginBottom: '10px' }}
                            />
                        </div>
                    </div>
                    <input
                        type="text"
                        className="form-control rounded-input"
                        placeholder="Fax Number"
                        style={{ marginBottom: '10px' }}
                        value={faxNumber}
                        onChange={(e) => setFaxNumber(e.target.value)}
                    />
                </div>
            </div>

            <div className="row input-row">
                <div className="col-3">
                    <p>
                        Website Url
                        <span style={{ color: 'red' }}>*</span>
                    </p>
                </div>
                <div className="col-6">
                    <input
                        type="text"
                        className="form-control rounded-input"
                        placeholder="Enter Website Url"
                        value={websiteUrl}
                        onChange={(e) => setWebsiteUrl(e.target.value)}
                    />
                </div>
                <div className="col-3"></div>
            </div>

            <div className="row mt-4">
                <div className="col-12 text-center">
                    <button
                        className="btn btn-primary"
                        style={{ marginRight: '10px' }}
                        onClick={ SaveButton }
                    >
                        Save
                    </button>
                    <button
                        className="btn btn-secondary"
                    >
                        Cancel
                    </button>
                </div>
            </div>

        </div>



        /*<div className={`alert-box ${type}`}>
            <span className="alert-message">{message}</span>
            <button className="close-button" onClick={onClose}>&times;</button>
        </div>*/
    );  
}

export default Organisation;