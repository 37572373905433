import React, { useEffect } from 'react';
//import './AlertBox.css'; // Import CSS for styling

function Customers({ type, message, onClose }) {

    return (
        <div >

            Hello Guys..This is Customers Page...!



        </div>
    );
}

export default Customers;